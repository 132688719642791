import CustomMargin from '../../components/custom-margin';
import AttyProfile from './components/atty-profile';
import MissionAbout from './components/mission-about';
import GlobalContact from '../../components/global-contact';

export default function AboutUs(){
    
    return(
        <div className='overflow-hidden'>
            <AttyProfile/>
            <div className='bg-buildingBg flex flex-col bg-cover shadow-inner-custom justify-center items-center text-white p-5 mb-10'>
                <MissionAbout/>
            </div>
            <CustomMargin>
                <div className='flex flex-col justify-center items-center h-auto mb-10'>
                    <div className='text-center font-fontMontserrat 
                                    sm:text-2xl sm:mb-5
                                    lg:text-4xl
                                    xxl:text-5xl'>
                        <p>Above all, peace of mind.</p>
                        <p>We will help you make things easier.</p>
                    </div>
                    <iframe className='h-[550px] w-[1100px] 
                                        sm:h-[150px] sm:w-[300px]
                                        lg:h-[350px] lg:w-[700px]
                                        xxl:h-[550px] xxl:w-[1100px]' 
                            title='Youtube QLO' 
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            src="https://www.youtube.com/embed/5__ArKQ-VeY?autoplay=1&modestbranding=1&playsinline=1&mute=1">
                    </iframe>
                </div>
            </CustomMargin>
        </div>
    );
}