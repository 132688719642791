import CustomMargin from '../../../components/custom-margin';
import AttyPicture from '../../../assets/atty-profile.png';

export default function AttyProfile(){
    return(
        <CustomMargin>
            <div className='flex'>
                <div className='p-5 sm:hidden lg:block'>
                    <img alt='Atty Profile' src={AttyPicture} className='h-auto'/>
                </div>
                <div className='p-5'>
                    <div className='border-b-2 border-b-black mb-5 pb-5 sm:text-center lg:text-start'>
                        <h2 className='font-bold text-4xl'>ATTY. ABELARDO CONSTANTINO QUILATON JR.</h2>
                        <p>Founding Owner</p>
                        <p className='mt-5'>
                            Quilaton Law Office is an all-specialization and independent law firm in Cebu City, Philippines that offers a wide range of legal services. Our law firm places a premium on competence, integrity, and professionalism to ensure maximum satisfaction among our clients. With years of service in the legal industry, we continuously and passionately expand our presence both locally and internationally.
                        </p>
                        <p>
                            Embracing the globally praised Filipino professionalism, our law firm recognizes the importance of fostering empathy and reliability for both clients and employees. We will actively listen to your concerns and inquiries because your problem is our problem. We also value personal attention by taking time to get to know you and your legal needs because we acknowledge that every legal case is unique.
                        </p>
                        <p>
                            In the service to the people, our law firm also recognizes the importance of affordable and reasonable rates in legal services. We guarantee first-rate legal services with minimal expenses for your optimal peace of mind. The Quilaton Law Office will always be known for its relentless pursuit of competence and excellence — with client peace of mind and satisfaction at the heart of our practice.
                        </p>
                    </div>
                    <div className='border-b-2 border-b-black mb-5 pb-5'>
                        <h2 className='font-bold text-4xl'>PROFESSIONAL BACKGROUND</h2>
                        <p className='mt-5'>a. Licensed Lawyer (All-Specialization) and Chief Executive Office (CEO) of Quilaton Law Office</p>
                        <p>b. Notary Public, Cebu City</p>
                        <p>c. Political Science Professor, University of Southern Philippines</p>
                        <p>d. Owner of Legal Viewpoint Philippines</p>
                    </div>
                    <div className='border-b-2 border-b-black mb-5 pb-5'>
                        <h2 className='font-bold text-4xl'>EDUCATIONAL BACKGROUND</h2>
                        <p className='mt-5'>Doctor of Jurisprudence, University of Southern Philippines</p>
                        <p>Bachelor of Science in Civil Engineering, University of San Carlos</p>
                        <p>Bachelor of Arts in Political Science, University of the Visayas</p>
                    </div>
                    <div className='mb-5 pb-5'>
                        <h2 className='font-bold text-4xl'>AREAS OF SPECIALIZATION, but not limited to:</h2>
                        <p className='mt-5'>Adaption Proceedings, Citizenship, Contracts, Construction, Corporate, Criminal, Civil Litigation, 
                            Elections, Establishment of Foriegn Representative Office, Family Law Case, Immigration, 
                            Intellectual Property Law, Incorporation of Business, Labor Disputes and Employment, Land Disputes, 
                            Registration, Negotation, Legal Outsourcing, Mediation-Arbitration, Settlement of Trust and Estates, 
                            Transportation and Maritime Law, Alternative Dispute Resolution</p>
                    </div>
                </div>
            </div>
        </CustomMargin>
    );
}